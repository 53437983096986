import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

export interface Props {
  children: React.ReactNode;
  className?: string;
}

export const TileWrapper = styled.div(
  ({ theme: { colours, shape } }) => css`
    position: relative;
    display: flex;
    min-height: unset;
    flex-direction: column;
    border: 1px solid ${colours.border.subdued};
    border-radius: ${shape.borderRadiusS}px;
    background: ${colours.surface.main};
  `,
);

export const tileStyles = ({ colours, shadows, shape, spacings }: Theme) => css`
  padding: ${spacings['24']}px ${spacings['16']}px;
  border-radius: ${shape.borderRadiusS}px;
  background: ${colours.surface.main};
  box-shadow: ${shadows.medium};
`;

export const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${tileStyles(theme)}
  `,
);

const Tile = ({ children, className }: Props) => {
  return <Container className={className}>{children}</Container>;
};

export default Tile;
